import { v4 as uuid } from 'uuid';

const dateWithoutTimezone = (date) => {
  if (!date) {
    return null;
  }
  const expires = new Date(date);
  const userTZOffset = expires.getTimezoneOffset() * 60000;
  return new Date(expires.getTime() + userTZOffset);
};

const supplementRuleData = (rules, ruleTemplate) => {
  return rules.map((rule, i) => {
    const { conditions, ...rest } = rule;
    const fields = { ...rest };
    const cond = conditions.map((r) => ({ id: uuid(), ...r }));
    for (const key of Object.keys(fields)) {
      switch (key) {
        case 'margin':
        case 'minimum_margin':
          fields[key] = Number(fields[key]);
          break;
        case 'min_pay_regular':
        case 'pay_travel':
          fields[key] = fields[key].toFixed(2);
          break;
        default:
          break;
      }
    }
    const priorityValue = fields?.priority || '';

    return {
      id: i + 1,
      open: false,
      conditions: cond,
      priority: {
        ...ruleTemplate.priority,
        value: { label: priorityValue, value: priorityValue?.split(' ')[0].toLowerCase() },
      },
      name: {
        ...ruleTemplate.name,
        value: fields.name,
      },
      expires_on: {
        ...ruleTemplate.expires_on,
        value: dateWithoutTimezone(fields.expires_on),
      },
      margin: {
        ...ruleTemplate.margin,
        value: fields.margin,
      },
      minimum_margin: {
        ...ruleTemplate.minimum_margin,
        value: fields.minimum_margin,
      },
      min_pay_regular: {
        ...ruleTemplate.min_pay_regular,
        value: fields.min_pay_regular,
      },
      pay_travel: {
        ...ruleTemplate.pay_travel,
        value: fields.pay_travel,
      },
      should_calculate_pay: {
        ...ruleTemplate.should_calculate_pay,
        value: fields.should_calculate_pay,
      },
    };
  });
};

export const marginRulesMutation = async (url, args, api, extraOptions, baseQuery) => {
  const state = api.getState();
  const ruleTemplate = state.margin.ruleTemplate;
  let body = new URLSearchParams({
    'division': args?.division,
    'version': args?.version,
  });
  if (args?.rules) {
    body = args?.rules;
  }
  try {
    const req = await baseQuery({
      url,
      method: 'POST',
      body,
    });
    let resp = null;
    if (req?.data) {
      resp = supplementRuleData(req?.data?.rules, ruleTemplate);
      return { data: resp };
    } else if (req?.error) {
      return { error: req?.error };
    }
  } catch (error) {
    console.log(error);
    return { error };
  }
};

export const marginRulesQuery = async (url, api, extraOptions, baseQuery) => {
  const state = api.getState();
  const ruleTemplate = state.margin.ruleTemplate;
  try {
    const req = await baseQuery({
      url,
      method: 'GET',
    });
    let resp = null;
    if (req?.data) {
      resp = supplementRuleData(req?.data?.rules, ruleTemplate);
      return { data: resp };
    } else if (req?.error) {
      return { error: req?.error };
    }
  } catch (error) {
    console.log(error);
    return { error };
  }
};
