import { useGetDataDumpsQuery, useRemoveDataDumpMutation } from '@/app/services/nucleus';
import { Box, Button, Flex, Heading, HStack, Spinner, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { useSelector, useDispatch } from 'react-redux';
import {
  getDumpBlob,
  selectDataDumpDownloadError,
  selectDataDumpDownloadStatus,
  selectDataDumpSearchStatus,
  selectDataDumpSortBy,
  selectDataDumpSortDirection,
  setDownloadError,
  setSearchStatus,
  setSortBy,
  setSortDirection,
} from './dataDumpSlice';
import AlertComponent from '@/features/common/AlertComponent';
import { ERROR, LOADING } from '@/utils/constants';
import { BsPlus, BsTrash } from 'react-icons/bs';
import DataDumpModal from './DataDumpModal';
import { setNotificationLogMessage } from '../notification/notificationSlice';
import TableButton from '../common/table/TableButton';
import ModalComponent from '../common/ModalComponent';
import { selectUserAdmin } from '../user/userSlice';
import { useNotification } from '@/hooks/useNotification';

const DataDumpPage = () => {
  const dispatch = useDispatch();
  const columnHelper = createColumnHelper();
  const dataDumpModal = useDisclosure();
  const removeDataDumpModal = useDisclosure();

  const admin = useSelector(selectUserAdmin);
  const searchStatus = useSelector(selectDataDumpSearchStatus);
  const downloadError = useSelector(selectDataDumpDownloadError);
  const downloadStatus = useSelector(selectDataDumpDownloadStatus);
  const sortBy = useSelector(selectDataDumpSortBy);
  const sortDirection = useSelector(selectDataDumpSortDirection);
  const [dumpID, setDumpID] = useState(null);
  const [currentDataDump, setCurrentDataDump] = useState(null);

  const [modalMode, setModalMode] = useState('add');

  const { data: dataDumps, isLoading, isError, error: dataDumpsError } = useGetDataDumpsQuery();

  const [
    removeDataDump,
    {
      isLoading: removeDataDumpLoading,
      isSuccess: removeDataDumpSuccess,
      isError: removeDataDumpError,
      error: removeDataDumpErrorMsg,
      reset: removeDataDumpReset,
    },
  ] = useRemoveDataDumpMutation();

  useEffect(() => {
    dispatch(setDownloadError(false));
  }, []);

  const handleFileDownload = (e, id) => {
    dispatch(setDownloadError(false));
    setDumpID(id);
    const response = dispatch(getDumpBlob(id));
    const blob = new Blob([response?.payload], { type: 'text/csv' });

    let anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = `data_dump_${id}.csv`;
    document.body.appendChild(anchor);
    anchor.click();
    anchor.parentNode.removeChild(anchor);
    // clean up
    URL.revokeObjectURL(anchor.href);
  };

  const handleDumpSortBy = (column) => {
    dispatch(setSortBy(column));
  };

  const handleSortDirection = (direction) => {
    dispatch(setSortDirection(direction));
  };

  const handleSearchStatus = (status) => {
    dispatch(setSearchStatus(status));
  };

  const handleSaveNotification = () => {
    dispatch(setNotificationLogMessage({ msg: `Data dump saved successfully`, status: 'success' }));
  };

  const handleNewDataDump = () => {
    setModalMode('add');
    dataDumpModal.onOpen();
  };

  const handleRemoveDataDump = (e, dumps) => {
    e.preventDefault();
    setCurrentDataDump(dumps);
    removeDataDumpModal.onOpen();
  };

  const deleteDataDump = (e) => {
    removeDataDump({ id: currentDataDump?.id })
      .unwrap()
      .then((payload) => {
        if (payload) {
          console.log('Data Dump Removed', payload);
        }
        removeDataDumpModal.onClose();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useNotification(removeDataDumpSuccess, removeDataDumpReset, removeDataDumpSuccess, 'success', 'Data dump removed successfully');

  const dataDumpsColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => (info.getValue() ? info.getValue() : 'None'),
    }),
    columnHelper.accessor('', {
      header: 'Download File',
      cell: (info) => {
        const dumps = info.row.original;
        return (
          <HStack>
            <Button
              isLoading={downloadStatus === 'loading' && dumpID === dumps.id}
              size="sm"
              variant="outline"
              onClick={(e) => handleFileDownload(e, dumps.id)}
            >
              Download
            </Button>
          </HStack>
        );
      },
    }),
    ...(admin
      ? [
          columnHelper.accessor('', {
            cell: (info) => {
              const dumps = info.row.original;
              return (
                <HStack>
                  <Tooltip label="Remove Data Dump" aria-label="remove-data-dump">
                    <Box p="0" as="span">
                      <TableButton aria-label="remove-data-dump" icon={<BsTrash />} onClick={(e) => handleRemoveDataDump(e, dumps)} />
                    </Box>
                  </Tooltip>
                </HStack>
              );
            },
            header: 'Action',
          }),
        ]
      : []),
  ];

  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Data Dumps
      </Heading>
      {downloadError && <AlertComponent status="error" title="Error" description="Error downloading file." />}
      <DataTableSearchAndFiltering
        title="Data Dumps"
        originalData={dataDumps}
        searchStatus={searchStatus}
        sortBy={sortBy}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || dataDumpsError}
        dataErrorMsg={dataDumpsError?.data?.detail}
        dataErrorTitle={'Error fetching data dumps'}
        dataColumns={dataDumpsColumns}
        handleSortBy={handleDumpSortBy}
        handleSortDirection={handleSortDirection}
        handleSearchStatus={handleSearchStatus}
        searchColumns={['name']}
        sortDirection={sortDirection}
      >
        {admin && (
          <Button leftIcon={<BsPlus />} variant="purple" type="submit" onClick={handleNewDataDump}>
            New
          </Button>
        )}
      </DataTableSearchAndFiltering>
      <DataDumpModal
        onOpen={dataDumpModal.onOpen}
        isOpen={dataDumpModal.isOpen}
        onClose={dataDumpModal.onClose}
        mode={modalMode}
        onSave={handleSaveNotification}
      />
      <ModalComponent
        icon={<BsTrash />}
        size="sm"
        title="Remove Data Dump"
        primaryText="Yes"
        secondaryText="No"
        handleConfirm={deleteDataDump}
        onOpen={removeDataDumpModal.onOpen}
        isOpen={removeDataDumpModal.isOpen}
        onClose={removeDataDumpModal.onClose}
        isError={false}
      >
        <Stack spacing={3}>
          {removeDataDumpLoading ? (
            <Flex alignItems="center" justifyContent="center">
              <Spinner color="brand.900" size="lg" />
            </Flex>
          ) : (
            <Box>
              <Text>{`Are you sure you want remove the ${currentDataDump?.name} data dump?`}</Text>
              {removeDataDumpError && (
                <AlertComponent status="error" title="Error removing data dump" description={removeDataDumpErrorMsg?.data?.detail} />
              )}
            </Box>
          )}
        </Stack>
      </ModalComponent>
    </>
  );
};

export default DataDumpPage;
