import { Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const RuleWrapper = ({ children, isOpen, isLast, isDeleted, isExpired, isPriority, id }) => {
  const bgCol = () => {
    if (isDeleted) return 'gray.100';
    if (isExpired) return 'red.50';
    return isOpen ? 'gray.50' : 'lightgray.50';
  };
  return (
    <Box
      width="100%"
      aria-expanded={isOpen}
      aria-controls={`rule-${id}`}
      aria-label={`rule-label-${id}`}
      p={6}
      sx={{
        zIndex: 1,
        position: 'relative',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.200',
        borderLeftWidth: '0',
        borderRightWidth: '0',
        opacity: isDeleted ? 0.5 : 1,
        borderBottomWidth: isLast ? '1px' : '0',
        backgroundColor: bgCol,
      }}
    >
      {children}
    </Box>
  );
};

RuleWrapper.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  isLast: PropTypes.bool,
  isDeleted: PropTypes.bool,
  isExpired: PropTypes.bool,
  id: PropTypes.number,
};
export default RuleWrapper;
