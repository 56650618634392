import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseUrl } from '@/app/services/nucleus';
import { setFailedRequests, setForceLogout, setLogoutStatus } from '@/features/login/loginSlice.js';
import { setNotificationLogMessage } from '@/features/notification/notificationSlice.js';

const initialState = {
  searchStatus: '',
  sortBy: 'name',
  sortDirection: 'asc',
  downloadStatus: '',
  downloadError: false,
};

export const getDumpBlob = createAsyncThunk('dumps/getDumpBlob', async (id, { getState, dispatch }) => {
  const token = getState()?.login.token;
  let headers = new Headers();
  headers.append('Authorization', `Bearer ${token}`);

  const response = await fetch(`${baseUrl}v1/dumps/${id}/download`, { headers });
  const msg = response?.error?.data?.detail;
  if (response.ok) {
    return await response.blob();
  } else if ([401, 429].includes(response?.status)) {
    dispatch(setForceLogout(true));
    dispatch(setLogoutStatus());
    if (getState()?.login.failedRequests === false) {
      dispatch(setNotificationLogMessage({ msg, status: 'error' }));
      dispatch(setFailedRequests(true));
    }
  } else if (response?.error && response?.status === 403) {
    dispatch(setNotificationLogMessage({ msg, status: 'error' }));
  }
});

export const dataDumpSlice = createSlice({
  name: 'dumps',
  initialState,
  reducers: {
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setDownloadError: (state, { payload }) => {
      state.downloadError = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDumpBlob.pending, (state) => {
        state.downloadStatus = 'loading';
      })
      .addCase(getDumpBlob.fulfilled, (state, result) => {
        state.downloadStatus = 'success';
      })
      .addCase(getDumpBlob.rejected, (state, result) => {
        state.downloadStatus = 'error';
        state.downloadError = true;
      });
  },
});

export const { setSearchStatus, setDownloadError, setSortBy, setSortDirection } = dataDumpSlice.actions;

export const selectDataDumpSearchStatus = (state) => state.dumps.searchStatus;
export const selectDataDumpDownloadError = (state) => state.dumps.downloadError;
export const selectDataDumpDownloadStatus = (state) => state.dumps.downloadStatus;
export const selectDataDumpSortBy = (state) => state.dumps.sortBy;
export const selectDataDumpSortDirection = (state) => state.dumps.sortDirection;

export default dataDumpSlice.reducer;
