import { useAddDataDumpsMutation } from '@/app/services/nucleus';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import LoaderOverlay from '../common/LoaderOverlay';
import AlertComponent from '../common/AlertComponent';
import { BiData } from 'react-icons/bi';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '../common/select/styles';
import { useSelector } from 'react-redux';
import { selectRolesList } from '../enum/enumSlice';
import { useRole } from '@/hooks/useRole';
import { selectUserAdmin } from '../user/userSlice';

const DataDumpModal = ({ isOpen, onClose, onSave, mode }) => {
  const roles = useSelector(selectRolesList);
  const [dataDumpName, setDataDumpName] = useState('');
  const [tableName, setTableName] = useState('');
  const [schemaName, setSchemaName] = useState('');
  const [role, setRole] = useState([]);

  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const labelColor = useColorModeValue('gray.400', 'gray.500');

  const [pageSize, setPageSize] = useState(10000); // Default value is set to 10000
  const [isInvalid, setIsInvalid] = useState(false);

  const [addDataDump, { isLoading: isAddDataDumpLoading, isError: isAddDataDumpError, error: addDataDumpError, reset: resetAddDataDump }] =
    useAddDataDumpsMutation();

  useEffect(() => {
    if (!isOpen) {
      setDataDumpName('');
      setTableName('');
      setSchemaName('');
      setRole([]);
      setPageSize(10000);
      setIsInvalid(false);
      resetAddDataDump();
    }
  }, [isOpen]);

  const handlePageSizeChange = (e) => {
    const value = e.target.value;

    // Convert the value to an integer when necessary for validation
    const intValue = value === '' ? '' : parseInt(value, 10);

    setPageSize(value);

    // Validation: input is invalid if it's empty, NaN, or less than or equal to 0
    if (value === '' || isNaN(intValue) || intValue <= 0) {
      setIsInvalid(true);
    } else {
      setIsInvalid(false);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleRoles = (selectOptions) => {
    setRole(selectOptions || []);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetAddDataDump();

    const dataDumpPayload = {
      limit: pageSize,
      allowed_roles: role.map((r) => r.value),
      name: dataDumpName,
      table_name: tableName,
      schema_name: schemaName,
    };

    addDataDump(dataDumpPayload)
      .unwrap()
      .then((payload) => {
        console.log('Data Dump Added', payload);
        onSave();
        onClose();
      })
      .catch((error) => {
        console.log('Error adding data dump', error);
      });
  };

  return (
    <Modal
      title={mode === 'edit' ? 'Edit Data Dump' : 'Add Data Dump'}
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="3xl"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <form id={`${mode}_data_dump`} onSubmit={handleSubmit}>
          <ModalHeader>
            <HStack>
              <BiData />
              <Text>{mode === 'edit' ? 'Edit Data Dump' : 'Add Data Dump'}</Text>
            </HStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing="4" position={'relative'}>
              <LoaderOverlay isLoading={isAddDataDumpLoading} />

              {isAddDataDumpError && (
                <AlertComponent
                  status="error"
                  title={mode === 'edit' ? 'Error updating data dump' : 'Error adding data dump'}
                  description={addDataDumpError?.data?.detail}
                />
              )}

              <FormControl id="data-dump-name" aria-label={'data-dump-name-control'}>
                <Grid templateColumns={'120px auto'}>
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="data-dump-name" mb={0} textAlign="end">
                      Data Dump
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="data-dump-name"
                      focusBorderColor="brand.700"
                      type="text"
                      isDisabled={false}
                      value={dataDumpName}
                      isRequired
                      onChange={(e) => setDataDumpName(e.target.value)}
                      placeholder="Enter Data Dump Name"
                      _placeholder={{ color: labelColor }}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl id="table-name" aria-label={'table-name-control'}>
                <Grid templateColumns={'120px auto'}>
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="table-name" mb={0} textAlign="end">
                      Table Name
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="table-name"
                      focusBorderColor="brand.700"
                      type="text"
                      isDisabled={false}
                      value={tableName}
                      isRequired
                      onChange={(e) => setTableName(e.target.value)}
                      placeholder='e.g. "VW_NETSUITE_USERS"'
                      _placeholder={{ color: labelColor }}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl id="schema-name" aria-label={'schema-name-control'}>
                <Grid templateColumns={'120px auto'}>
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="schema-name" mb={0} textAlign="end">
                      Schema Name
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="schema-name"
                      focusBorderColor="brand.700"
                      type="text"
                      isDisabled={false}
                      value={schemaName}
                      isRequired
                      onChange={(e) => setSchemaName(e.target.value)}
                      placeholder='e.g. "TRIAGENETSUITE_ODBC"'
                      _placeholder={{ color: labelColor }}
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl isInvalid={isInvalid} aria-label={`page-size-control`}>
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="page_size" mb={0} textAlign="end">
                      Page Size
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Input
                      id="page_size"
                      type="number"
                      min="1"
                      value={pageSize}
                      onChange={handlePageSizeChange}
                      placeholder="Enter limit"
                      _placeholder={{ color: labelColor }}
                    />
                    {isInvalid && <FormErrorMessage>Limit must be greater than 0.</FormErrorMessage>}
                  </GridItem>
                </Grid>
              </FormControl>

              <FormControl id="role" aria-label="role-control">
                <Grid templateColumns="120px auto">
                  <GridItem pt={2}>
                    <FormLabel color={labelColor} fontSize="md" fontWeight={300} htmlFor="role" mb={0} textAlign="end">
                      Role
                    </FormLabel>
                  </GridItem>
                  <GridItem>
                    <Select
                      id="role"
                      aria-label="role"
                      chakraStyles={chakraStyles}
                      options={roles}
                      isMulti
                      onChange={handleRoles}
                      value={role}
                      colorScheme="cyan"
                      isRequired
                      getOptionLabel={(option) => `${option.label}`}
                      getOptionValue={(option) => `${option.value}`}
                      placeholder="Select a Role"
                    />
                  </GridItem>
                </Grid>
              </FormControl>

              <HStack width="100%" py={3} justifyContent={'end'}>
                <Button variant="purple" type="submit">
                  {mode === 'edit' ? 'Save' : 'Submit'}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default DataDumpModal;
