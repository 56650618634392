export const chakraStyles = {
  container: (provided) => ({
    ...provided,
    flex: 'auto',
    backgroundColor: 'white',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 1000,
  }),
  option: (provided, state) => ({
    ...provided,
    ...(state.isSelected && {
      backgroundColor: 'cyan.50',
      color: 'gray.900',
    }),
  }),
};
