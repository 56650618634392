import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Heading,
  HStack,
  Text,
  InputGroup,
  Input,
  FormErrorMessage,
  Spacer,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Switch,
  InputLeftElement,
  Tooltip,
  Portal,
  Badge,
  InputRightElement,
  Tag,
  TagLeftIcon,
} from '@chakra-ui/react';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { Select } from 'chakra-react-select';
import { chakraStyles } from '@/features/common/select/styles.js';
import { datePickerConfig } from '../common/datePickerConfig';
import { FiArrowUp, FiArrowDown, FiMoreVertical, FiTrash, FiPlus } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { selectOperatorList, selectPriorityList } from '../enum/enumSlice';
import LinkButton from '../common/LinkButton';
import { ClearIndicator } from '../common/select/ClearIndicator';
import { formatDateYearMonthDay, isArrayValue, isRealString } from '@/utils/helpers';
import MultiValueRemove from '../common/select/MultiValueRemove';
import { AnimatePresence, motion } from 'framer-motion';
import RuleNumber from '@/features/margin/RuleNumber';
import RuleWrapper from './RuleWrapper';
import RuleHeader from '@/features/margin/RuleHeader.jsx';
import { BsX } from 'react-icons/bs';
import { RxTimer } from 'react-icons/rx';
import { FaFire } from 'react-icons/fa';

// NOTE: parses out nested fields recursively
// WARN: some values may not map, in which case we set their value explicitly
const parseConditionFieldName = (options, job, i = 0) => {
  let result = [];
  let foundJob = {};
  const jobObj = job?.items ? job.items : job;
  if (jobObj && options && options.length > 0) {
    foundJob = jobObj.find((j) => j?.value === options[i]);
    if (foundJob) {
      result.push(foundJob);
    }
    // NOTE: (options.length > i + 1) ensures we stop recursion when all options are parsed
    if (foundJob?.items && foundJob.items.length > 0 && options.length > i + 1) {
      result = result.concat(parseConditionFieldName(options, foundJob, i + 1));
    } else if (!foundJob) {
      result.push({ value: options[i], type: 'string' });
    }
  }
  return result;
};

const initialCondition = {
  id: uuid(),
  field: {
    options: [],
    value: null,
    cache: [],
  },
  operator: null,
  input: {
    value: '',
    type: 'string',
  },
};

const Conditions = ({
  item,
  DragHandle,
  readOnly,
  first,
  last,
  onSortUp,
  onSortDown,
  onDeleteRule,
  jobOptions,
  onConditionsUpdate,
  onRuleUpdate,
  onExpirationUpdate,
}) => {
  const operators = useSelector(selectOperatorList);
  const priorityOptions = useSelector(selectPriorityList);
  const timerRef = useRef(null);
  const [name, setName] = useState(item?.name?.value ?? '');
  const [marginGoal, setMarginGoal] = useState(item?.margin?.value ?? 0);
  const [minMarginGoal, setMinMarginGoal] = useState(item?.minimum_margin?.value ?? 0);
  const [minPay, setMinPay] = useState(item?.min_pay_regular?.value ?? 0);
  const [payTravel, setPayTravel] = useState(item?.pay_travel?.value ?? 0);
  const [expired, setExpired] = useState(false);
  const [animate, setAnimate] = useState(false);

  // allows disabling animations for expand/collapse all
  const animationAttributes = animate
    ? { initial: { height: 0, opacity: 0 }, animate: { opacity: 1, height: 'auto' }, exit: { opacity: 0, height: 0 }, transition: { duration: 0.1 } }
    : {};

  useEffect(() => {
    setAnimate(false);
    setName(item?.name?.value ?? '');
    setMinMarginGoal(item?.minimum_margin?.value ?? 0);
    setMarginGoal(item?.margin?.value ?? 0);
    setMinPay(item?.min_pay_regular?.value ?? 0);
    setPayTravel(item?.pay_travel?.value ?? 0);
    if (item?.expires_on?.value) {
      const now = new Date();
      const expires = new Date(item.expires_on.value);
      setExpired(now > expires);
    } else {
      setExpired(false);
    }
  }, [item]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const debounceRuleUpdates = (e, id, field) => {
    // NOTE: debounce is disabled in test mode
    if (import.meta.env.MODE === 'test') {
      onRuleUpdate(e, id, field);
      return;
    }
    timerRef && clearTimeout(timerRef);
    switch (field) {
      case 'name':
        setName(e.target.value);
        break;
      case 'margin':
        setMarginGoal(e.target.value);
        break;
      case 'minimum_margin':
        setMinMarginGoal(e.target.value);
        break;
      case 'min_pay_regular':
        setMinPay(e.target.value);
        break;
      case 'pay_travel':
        setPayTravel(e.target.value);
        break;
    }
    timerRef.current = setTimeout(() => {
      onRuleUpdate(e, id, field);
    }, 500);
  };

  // WARN: idempotent computations only. i.e. can't use uuid(), etc.
  const conditions = useMemo(() => {
    let cond = [];
    if (isArrayValue(item?.conditions) && isArrayValue(jobOptions)) {
      for (const c of item.conditions) {
        // contains field value, which we parse into an array for the multi-select
        const options = c?.field_name ? c?.field_name.split('.') : null;
        // contains the job schema for the field specified from rules API
        let jobDataFromSchema = null;
        // contains the list of options for the field's "parent" item.
        let cache = [];
        // list of options to display in the select component
        let values = [];
        // contains the last value in the select component to determine the input type
        let lastValue = {};
        // parse conditions values passed in
        if (options && options.length > 0) {
          jobDataFromSchema = jobOptions.find((j) => j?.value === options[0]);
          values = parseConditionFieldName(options, jobOptions);
          // populate the options cache so the user can remove/edit conditions loaded from API
          if (values && values.length > 0) {
            cache = values.filter((val) => val?.items).map((c) => c?.items);
            lastValue = values[values.length - 1];
          } else {
            lastValue = values[0];
          }
        }
        // if user added a new condition
        if (!options) {
          const newCondition = structuredClone(initialCondition);
          if (c?.id) {
            newCondition.id = c.id;
          }
          newCondition.operator = operators.find((op) => op?.value === c?.operator) ?? null;
          newCondition.field.options = jobOptions;
          newCondition.input.value = c?.value ?? '';
          cond.push(newCondition);
        } else {
          // NOTE: this has to account for the following scenarios
          // 1. the field has children + all children are selected
          // 2. the field has children + the next child is not selected
          // 3. the field has no children
          cond.push({
            id: c?.id,
            field: {
              options: cache && options.length === cache.length ? cache[cache.length - 1] : [],
              cache: values && values.length === 0 ? [] : [jobOptions, ...cache],
              value: values ? [...values] : jobDataFromSchema,
            },
            operator: operators.find((op) => op?.value === c?.operator) ?? null,
            input: {
              value: c?.value ?? '',
              type: lastValue?.type ?? 'string',
            },
          });
        }
      }
    }
    return cond;
  }, [item, jobOptions]);

  const handleFieldChange = (value, { action }, id) => {
    const theCondition = conditions.find((cond) => cond?.id === id);
    let prevOptions = theCondition?.field?.options;
    let direction = 'push';
    let nextOptions = [];
    // NOTE: previous options are stored in the 'cache' field as a LIFO structure
    if (action === 'select-option') {
      const val = isArrayValue(value) ? value[value.length - 1] : value;
      if (val?.items) {
        nextOptions = val?.items;
      }
    } else if (action === 'clear') {
      nextOptions = theCondition?.field?.cache[0];
    } else if (action === 'pop-value' || action === 'remove-value') {
      direction = 'pop';
      const len = theCondition.field.cache.length;
      // NOTE: the user may hit backspace when the field is empty
      if (len > 0) {
        nextOptions = theCondition.field.cache[len - 1];
      } else {
        nextOptions = prevOptions;
      }
    }
    const modConditions = conditions.map((c) => {
      const copy = { ...c };
      if (copy?.id === id) {
        // NOTE: when remove-value is used, this is set to empty array
        const field = { ...copy.field };
        field.value = value;
        // handle input type
        let lastValue = {};
        if (isArrayValue(value)) {
          lastValue = value[value.length - 1];
        } else {
          lastValue = value[0];
        }
        const input = { ...copy.input };
        let operator = { ...copy.operator };
        input.type = lastValue?.type ?? 'string';
        if (direction === 'pop') {
          field.cache.pop();
        } else {
          field.cache.push(prevOptions);
        }
        // clear other fields
        input.value = '';
        operator = null;
        // assign new values
        field.options = nextOptions;
        copy.field = { ...field };
        copy.input = { ...input };
        copy.operator = operator;
      }
      return copy;
    });
    onConditionsUpdate(item.id, modConditions);
  };

  const filterOperators = (condition, operators) => {
    const len = condition?.field?.value ? condition.field.value.length : 0;
    if (condition?.field?.value && len > 0) {
      const lastOption = condition.field.value[len - 1];
      const type = lastOption?.type;
      const numberTypes = [1, 2, 3, 4, 5, 6];
      const stringTypes = [1, 2, 9, 10];
      const enumTypes = [1, 2, 10];
      let ops = [];
      switch (type) {
        case 'string':
          ops = stringTypes;
          break;
        case 'enum':
          ops = enumTypes;
          break;
        default:
          ops = numberTypes;
      }
      return operators.filter((op) => ops.includes(op?.value));
    }
    return operators;
  };

  const filterEnumOptions = (condition, operators) => {
    const len = condition?.field?.value ? condition.field.value.length : 0;
    if (condition?.field?.value && len > 0) {
      const lastOption = condition.field.value[len - 1];
      const type = lastOption?.type;
      if (type === 'enum') {
        return lastOption?.enum.map((e) => ({ value: e, label: e }));
      }
    }
    return null;
  };

  const handleInputValueChange = (e, id) => {
    const val = e.target.value;
    const modConditions = conditions.map((c) => {
      if (c?.id === id) {
        c.input.value = val;
      }
      return c;
    });
    onConditionsUpdate(item.id, modConditions);
  };

  const handleOperatorChange = (value, id) => {
    const modConditions = conditions.map((c) => {
      if (c?.id === id) {
        c.operator = value;
      }
      return c;
    });
    onConditionsUpdate(item.id, modConditions);
  };

  const handlePriorityChange = (value, id) => {
    const fakeEvent = { target: { value: value.label } };
    onRuleUpdate(fakeEvent, id, 'priority');
  };

  const handleEnumSelectChange = (value, id) => {
    const modConditions = conditions.map((c) => {
      if (c?.id === id) {
        c.input.value = value?.label;
      }
      return c;
    });
    onConditionsUpdate(item.id, modConditions);
  };

  const handleRemoveCondition = (e, id) => {
    const filterConditions = conditions.filter((c) => c?.id !== id);
    onConditionsUpdate(item.id, filterConditions);
  };

  const handleAddCondition = (e) => {
    const newOption = structuredClone(initialCondition);
    newOption.id = uuid();
    const newConditions = [...conditions, newOption];
    onConditionsUpdate(item.id, newConditions);
  };

  const handleAccordionToggle = (e, id) => {
    setAnimate(true);
    onRuleUpdate(e, id, 'open');
  };

  return (
    <RuleWrapper id={item?.id} isOpen={item?.open} isLast={last} isDeleted={item?.deleted} isExpired={expired}>
      <RuleHeader>
        <DragHandle mt={1} isDisabled={item?.deleted} height="45px" />
        <Heading as="h4" fontWeight="bold" p={2} fontSize="0.9rem" pt={3}>
          <RuleNumber>{item?.id}</RuleNumber>
          {item?.name?.value || 'Margin Rule'}{' '}
        </Heading>
        {(item?.priority?.value?.value === 'hot' || item?.priority?.value?.value === 'exclusive') && (
          <HStack ml={3} height="45px" alignContent="center">
            <Tag variant="subtle" colorScheme={'red'} borderRadius={6} p={1}>
              <TagLeftIcon boxSize="15px" as={FaFire} />
              {item?.priority?.value?.label}
            </Tag>
          </HStack>
        )}
        {item?.expires_on?.value && (
          <HStack ml={3} height="45px" alignContent="center">
            <Tag variant="subtle" colorScheme={expired ? 'red' : 'yellow'} borderRadius={6} p={1}>
              <TagLeftIcon boxSize="15px" as={RxTimer} />
              {expired ? 'expired' : ' ' + formatDateYearMonthDay(item?.expires_on?.value)}
            </Tag>
          </HStack>
        )}
        {item?.deleted && (
          <Box>
            <Badge>deleted</Badge>
          </Box>
        )}
        <Spacer data-testid={`rule-header-${item?.id}`} as="div" role="button" cursor="pointer" onClick={(e) => handleAccordionToggle(e, item.id)} />
        {!first && (
          <Tooltip label="Move Up" placement="top">
            <IconButton
              onClick={(e) => onSortUp(e, item?.id)}
              icon={<FiArrowUp />}
              variant="ghost"
              colorScheme="gray"
              aria-label="up"
              isDisabled={readOnly || item?.deleted}
            />
          </Tooltip>
        )}
        {!last && (
          <Tooltip label="Move Down" placement="top">
            <IconButton
              onClick={(e) => onSortDown(e, item?.id)}
              icon={<FiArrowDown />}
              variant="ghost"
              colorScheme="gray"
              aria-label="down"
              isDisabled={readOnly || item?.deleted}
            />
          </Tooltip>
        )}
        <Menu>
          <MenuButton
            aria-label="more"
            isDisabled={item?.deleted}
            px={3}
            py={3}
            variant="ghost"
            transition="all 0.2s"
            _hover={{ bg: 'gray.200' }}
            _expanded={{ bg: 'gray.300' }}
            as={IconButton}
            icon={<FiMoreVertical />}
          />
          <Portal>
            <MenuList zIndex="popover">
              <MenuItem aria-label="Delete Rule" onClick={(e) => onDeleteRule(e, item)} isDisabled={readOnly}>
                Delete Rule
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </RuleHeader>
      <AnimatePresence initial={false}>
        {item.open && (
          <motion.div {...animationAttributes}>
            <Box py={6} mt={4}>
              <HStack py={3}>
                <FormControl>
                  <FormLabel>Name</FormLabel>
                  <Input
                    aria-label="name"
                    type="text"
                    bg="white"
                    value={name}
                    placeholder="Margin Rule"
                    focusBorderColor={'brand.700'}
                    onChange={(e) => debounceRuleUpdates(e, item.id, 'name')}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="expires_on">Expires On</FormLabel>
                  <InputGroup sx={{ zIndex: 102 }}>
                    <SingleDatepicker
                      id="expires_on"
                      name="expires_on"
                      date={item?.expires_on?.value || undefined}
                      onDateChange={(val) => onExpirationUpdate(val, item.id)}
                      propsConfigs={datePickerConfig({ name: 'expires_on' })}
                    />
                    {isRealString(item?.expires_on?.value) && (
                      <InputRightElement>
                        <IconButton
                          onClick={(e) => onExpirationUpdate('clear', item.id)}
                          icon={<BsX />}
                          variant="ghost"
                          size="md"
                          aria-label={`clear-${item?.name}`}
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="priority">Priority</FormLabel>
                  <Select
                    id="priority-select"
                    aria-label="priority-select"
                    chakraStyles={chakraStyles}
                    focusBorderColor="brand.700"
                    value={item?.priority?.value}
                    options={priorityOptions}
                    menuPosition="fixed"
                    onChange={(val) => handlePriorityChange(val, item.id)}
                  />
                </FormControl>
              </HStack>

              <Box>
                <FormLabel
                  as="h5"
                  sx={{
                    fontSize: 'md',
                    marginBottom: '10px',
                  }}
                >
                  Conditions
                </FormLabel>
              </Box>
              <FormControl
                as="fieldset"
                sx={{
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  borderRadius: 'md',
                  backgroundColor: 'white',
                }}
              >
                <HStack justifyContent={'space-around'} spacing={5} px={5} py={2}>
                  <Box minWidth="420px" width="100%">
                    <Text>Field</Text>
                  </Box>
                  <Box minWidth="100px">
                    <Text>Operator</Text>
                  </Box>
                  <Box width={'100%'}>
                    <Text>Value</Text>
                  </Box>
                </HStack>
                {conditions?.map((c, i) => (
                  <HStack
                    key={c?.id}
                    justifyContent={'space-around'}
                    spacing={5}
                    px={5}
                    paddingBottom={3}
                    aria-label={`condition_${item?.id}_field_wrapper_${i + 1}`}
                  >
                    <Box minWidth={'420px'} width={'100%'}>
                      <Select
                        size="sm"
                        isDisabled={readOnly}
                        aria-label={`condition_${item?.id}_field_${i + 1}`}
                        chakraStyles={chakraStyles}
                        focusBorderColor="brand.700"
                        options={c?.field?.options}
                        onChange={(val, action) => handleFieldChange(val, action, c?.id)}
                        isMulti
                        isSearchable
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 200 }) }}
                        value={c?.field?.value}
                        placeholder="Select a field"
                        components={{ MultiValueRemove, ClearIndicator }}
                        getOptionLabel={(option) => `${option.value}`}
                        getOptionValue={(option) => option.value}
                      />
                      <FormErrorMessage aria-label={'condition_1_field_1_error'}>{'error'}</FormErrorMessage>
                    </Box>
                    <Box minWidth={'100px'}>
                      <Select
                        size="sm"
                        isDisabled={readOnly}
                        aria-label={`condition_${item?.id}_operator_${i + 1}`}
                        chakraStyles={chakraStyles}
                        focusBorderColor="brand.700"
                        options={filterOperators(c, operators)}
                        placeholder=""
                        onChange={(val) => handleOperatorChange(val, c?.id)}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 200 }) }}
                        value={c?.operator}
                      />
                      <FormErrorMessage aria-label={'condition_1_operator_1_error'}>{'error'}</FormErrorMessage>
                    </Box>
                    <Box width={'100%'}>
                      {c?.input?.type === 'number' ? (
                        <Input
                          aria-label={`condition_${item?.id}_value_${i + 1}`}
                          size="sm"
                          isDisabled={readOnly}
                          placeholder="Enter a value"
                          focusBorderColor={'brand.700'}
                          type="number"
                          min={0}
                          step={0.01}
                          onChange={(e) => handleInputValueChange(e, c?.id)}
                          value={c?.input.value}
                        />
                      ) : c?.input?.type === 'enum' ? (
                        <React.Fragment>
                          <Select
                            size="sm"
                            isDisabled={readOnly}
                            aria-label={`condition_${item?.id}_enumSelect_${i + 1}`}
                            chakraStyles={chakraStyles}
                            focusBorderColor="brand.700"
                            options={filterEnumOptions(c, c?.field?.value)}
                            placeholder="Select a value"
                            onChange={(val) => handleEnumSelectChange(val, c?.id)}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 200 }) }}
                            value={c?.input?.value ? { label: c?.input?.value, value: c?.input?.value } : null}
                          />
                          <FormErrorMessage aria-label={'condition_1_operator_1_error'}>{'error'}</FormErrorMessage>
                        </React.Fragment>
                      ) : (
                        <Input
                          aria-label={`condition_${item?.id}_value_${i + 1}`}
                          size="sm"
                          isDisabled={readOnly}
                          placeholder="Enter a value"
                          focusBorderColor={'brand.700'}
                          type={c?.input?.type ?? 'text'}
                          onChange={(e) => handleInputValueChange(e, c?.id)}
                          value={c?.input.value}
                        />
                      )}
                      <FormErrorMessage aria-label={'condition_1_value_1_error'}>{'error'}</FormErrorMessage>
                    </Box>
                    <Box width={'40px'}>
                      <IconButton
                        variant={'ghost'}
                        aria-label="remove"
                        icon={<FiTrash />}
                        onClick={(e) => handleRemoveCondition(e, c?.id)}
                        isDisabled={readOnly}
                      />
                    </Box>
                  </HStack>
                ))}
                <Box p={5}>
                  <LinkButton icon={<FiPlus />} onClick={(e) => handleAddCondition(e)} isDisabled={readOnly}>
                    Add New Condition
                  </LinkButton>
                </Box>
              </FormControl>
            </Box>
            <HStack mt={3} mb={1} spacing={8}>
              <FormControl isInvalid={!item?.margin?.valid || false}>
                <FormLabel>{item?.margin?.label}</FormLabel>
                <Box height="50px">
                  <Tooltip label="For gross margin percentage, enter a value between 0-1. Values greater than 1 will be considered as weekly gross margin dollar amounts.">
                    <Input
                      aria-label="margin-goal"
                      size="sm"
                      isDisabled={readOnly}
                      type="number"
                      bg="white"
                      value={marginGoal}
                      onChange={(e) => debounceRuleUpdates(e, item.id, 'margin')}
                    />
                  </Tooltip>
                  <FormErrorMessage>{item?.margin?.error}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl isInvalid={!item?.minimum_margin?.valid || false}>
                <FormLabel>{item?.minimum_margin?.label}</FormLabel>
                <Box height="50px">
                  <Tooltip label="For gross margin percentage, enter a value between 0-1. Values greater than 1 will be considered as weekly gross margin dollar amounts.">
                    <Input
                      size="sm"
                      isDisabled={readOnly}
                      type="number"
                      bg="white"
                      value={minMarginGoal}
                      onChange={(e) => debounceRuleUpdates(e, item.id, 'minimum_margin')}
                    />
                  </Tooltip>
                  <FormErrorMessage>{item?.minimum_margin?.error}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl isInvalid={!item?.min_pay_regular?.valid || false}>
                <FormLabel>{item?.min_pay_regular?.label}</FormLabel>
                <Box height="50px">
                  <InputGroup size={'sm'}>
                    <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                      $
                    </InputLeftElement>
                    <Input
                      size="sm"
                      isDisabled={readOnly}
                      type="number"
                      bg="white"
                      min="0.01"
                      max="2500"
                      step="0.01"
                      value={minPay}
                      onChange={(e) => debounceRuleUpdates(e, item.id, 'min_pay_regular')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{item?.min_pay_regular?.error}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl isInvalid={!item?.pay_travel?.valid || false}>
                <FormLabel>{item?.pay_travel?.label}</FormLabel>
                <Box height="50px">
                  <InputGroup size={'sm'}>
                    <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                      $
                    </InputLeftElement>
                    <Input
                      size="sm"
                      isDisabled={readOnly}
                      type="number"
                      bg="white"
                      value={payTravel}
                      onChange={(e) => debounceRuleUpdates(e, item.id, 'pay_travel')}
                    />
                  </InputGroup>
                  <FormErrorMessage>{item?.pay_travel?.error}</FormErrorMessage>
                </Box>
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="calculatePay">{item?.should_calculate_pay?.label}</FormLabel>
                <Box height="50px">
                  <Switch
                    id="calculatePay"
                    isDisabled={readOnly}
                    size="lg"
                    colorScheme="cyan"
                    isChecked={item?.should_calculate_pay?.value}
                    onChange={(e) => onRuleUpdate(e, item.id, 'should_calculate_pay')}
                  />
                </Box>
              </FormControl>
            </HStack>
          </motion.div>
        )}
      </AnimatePresence>
    </RuleWrapper>
  );
};
Conditions.propTypes = {
  item: PropTypes.object,
  DragHandle: PropTypes.any,
  first: PropTypes.bool,
  last: PropTypes.bool,
  readOnly: PropTypes.bool,
  onSortUp: PropTypes.func,
  onSortDown: PropTypes.func,
  onDeleteRule: PropTypes.func,
  jobOptions: PropTypes.array,
  onConditionsUpdate: PropTypes.func,
  onRuleUpdate: PropTypes.func,
  onExpirationUpdate: PropTypes.func,
};
export default Conditions;
